"use client";

import { useRouter } from "next/navigation";
import Link from "next/link";
import { FileXIcon } from "lucide-react";

import { Button, buttonVariants } from "@/components/ui/button";

const HOME_PATH = "/";

export default function NotFound() {
  const router = useRouter();

  return (
    <>
      <main className="flex min-h-screen items-center justify-center">
        <div className="flex max-w-[340px] flex-col">
          <div className="mb-5">
            <FileXIcon strokeWidth={1} className="mx-auto h-[50px] w-[50px]" />
          </div>
          <div className="flex flex-row items-center justify-center	space-x-3">
            <div className="text-xl">404</div>
            <div className="text-muted-foreground">|</div>
            <div className="text-lg">Page Not Found</div>
          </div>
          <div className="py-3 text-center text-sm leading-6 text-muted-foreground">
            Please check the URL or try again later.
          </div>
          <div className="flex flex-row justify-center space-x-5 py-3">
            <Link
              className={buttonVariants({ variant: "outline" })}
              href={HOME_PATH}
            >
              Back to Home
            </Link>
            <Button variant={"outline"} onClick={() => router.back()}>
              Back to Previous Page
            </Button>
          </div>
        </div>
      </main>
    </>
  );
}
